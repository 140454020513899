
<template>
  <v-container fluid>
    <page-header title='Detalhes do usuário'></page-header>
    <one-user></one-user>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: "ShowUser",
  components: {
    OneUser: () => import('../../components/user/OneUser'),
  },
  data() {
    return {
      key: null
    }
  },
  methods: {
    names() {
      
    },
    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    name() {
      return null 
    },
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  watch: {
    data(nv,ov) {
      if (nv){
      } else if (nv == false){
      }
    }
  },
}
</script>

<style scoped>

</style>